import React from 'react'

function LayoutAuth() {
  return (
    <div>
      logins
    </div>
  )
}

export default LayoutAuth
