import { React } from "react";
;

const Sinsaltillo2024 = ({ nombre, mdstatus, eseditable }) => {
    return(
        <div className=" w-full h-full">
        <iframe className=" w-full h-full border-0"
        src="https://docs.google.com/spreadsheets/d/1obwgCRuZBEeug1yJGtONp83jRMkrsOy55zX62X4Q7uI/edit#gid=1201892089" 
        allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe></div>
    )
}
export default Sinsaltillo2024;