import React from 'react'

function Error404() {
  return (
   < div className="relative  flex flex-row items-center justify-center text-violet-900 inset-0 text-4xl z-50  ">
    ERROR 404
</div>
  )
}

export default Error404
