import logosinestryfgr from "../../auth/Login/logo_footer.png"
const Footerlogin = () => {
  return (
    <footer>
    <div class="footer-content">
        <img
          class="footer-logo" 
          src={logosinestryfgr}
          alt="Placeholder del logotipo"
        />
      <nav>
      <a href="https://sinestry.com/aviso-de-privacidad/" target="_blank">Aviso de Privacidad</a>
      <a href="https://sinestry.com/politica-sgi/" target="_blank">Política SGI</a>
        <span className="text-[1.5rem]">© SINESTRY 2024</span>
      </nav>
    </div>
  </footer>
  );
};
export default Footerlogin;
