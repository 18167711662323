import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { getStorage ,ref,uploadBytes} from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyAHafTjzHW-ZLAveXDapmpv22wpE3FpbMg",
  authDomain: "jkdemo001.firebaseapp.com",
  projectId: "jkdemo001",
  storageBucket: "jkdemo001.appspot.com",
  messagingSenderId: "552789706120",
  appId: "1:552789706120:web:7a229ad2125c3828a4263d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app); // auth 

export const storage = getStorage(app);// storage 

export function upfileFirebase(file) {
 const strageRef = ref(storage, file.name);
  uploadBytes(strageRef, file).then((snapshot) => {

  })
}
const provider = new GoogleAuthProvider()
export const signInWithGoogle = () => {
  signInWithPopup(auth, provider)
    .then((result) => {
      
      })
    .catch((err) => {});
};
