import {
  React,
  useState,
  useEffect,
  createElement,
  useRef,
  createContext,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import logofooter from "../auth/Login/logo_footer.png";
import axios from "axios";
import { auth } from "../data/fire";

const Sidebar3 = ({ autusuario }) => {
  const usrimg = autusuario.photoURL;
  const usuario = autusuario.displayName;
  const email = autusuario.email;
  const [expanded, setExpanded] = useState(true);
  const [submenuformal, setSubmenuformal] = useState(false);
  const [subcontrolfact, setSubcontrolfact] = useState(false);
  const [submenurentabilidad, setSubmenurentabilidad] = useState(false);
  const [submenucotizador,setSubmenucotizador]= useState(false);
  const [submenucapacitacion,setSubmenucapacitacion]= useState(false);
  const [submenuestaditicas, setSubmenuestaditicas] = useState(false);
  useEffect(() => {});
  function handleClick() {
    setExpanded((curr) => !curr);
    setSubmenuformal(false);
    setSubcontrolfact(false);
  }
  function handleClose() {
    auth.signOut();
  }

  return (
    <div className={`sidebar ${expanded ? "active" : ""}`}>
      <div className="top">
        <div className="logo">
          <img src={logofooter} alt="" />
        </div>
        <i onClick={handleClick} className="bx bx-menu" id="btn"></i>
      </div>
      <div className="user grid grid-cols-1 items-center ">
        <div className="flex justify-center  col-span-1">
          <img
            className={`${submenuformal ? "hidden" : ""} ${expanded ? " w-[28%] rounded-[50%]" : "w-[42%] rounded-[50%]"}`}
            src={usrimg}
            alt=""
          />
        </div>
        <div className={`col-span-1 ${expanded ? "-mb-[4vh]":"-mb-[9vh]"} `}>
          <p className="bold text-[12px]">{usuario}</p>
          <p className="text-[10px]">{email}</p>
        </div>
      </div>
      <div><ul className="w-full text-[1.5rem]">
        <li>
          <Link
           onClick={() => {
            setSubmenuformal(false);
            setSubcontrolfact(false);
            setSubmenurentabilidad(false);
            setSubmenucotizador(false);
            setSubmenucapacitacion(false);
            setSubmenucotizador(false);
            setSubmenucapacitacion(false);
            setExpanded(false);
          }}
            to="/"
          >
            <i
              className={`bx bx-home ${expanded ? "text-[1.5rem]" : "text-[2rem]"}`}
            ></i>
            <span className="nav-item">Inicio</span>
          </Link><span className="tooltip">Inicio</span>
        </li>

        <li>
          <Link
            onClick={() => {
              setSubmenuformal(!submenuformal);
              setSubcontrolfact(false);
              setSubmenurentabilidad(false);
              setSubmenucotizador(false);
              setSubmenucapacitacion(false);
              setExpanded(false);
            }}
          >
            <i
              className={`bx bx-notepad ${expanded ? "text-[1.5rem]" : "text-[2rem]"}`}
            ></i>
            <span className="nav-item">{expanded ? "Siniestralidad" : ""}</span>
          </Link><span className="tooltip">Siniestralidad</span>

          <div
            className={`absolute -top-[1vh] h-auto z-50 bg-grisSinestry rounded-md ${submenuformal ? "" : "hidden"} 
           ${expanded ? "w-full ml-[15vw] mr-2" : "ml-[3vw] w-[40vw] h-auto rounded-md shadow-gray-100 "}`}
          >
            <ul className="w-full flex flex-row justify-center text-white">
              <li className="w-full bg-grisSinestry">
                <span className="w-full items-center pl-20">2023</span>
                <ul className="pl-5 bg-grisSinestry">
                  <li>
                    <Link
                      className="flex items-center -mt-2 h-11 py-2"
                      onClick={() => {
                        setSubmenuformal(!submenuformal);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                      to="/sin/2023/tijuana"
                    >
                      <span className="">
                        {" "}
                        <i class="bx bx-chevron-right"></i>Tijuana
                      </span>
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/sin/2023/hermosillo"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">
                        {" "}
                        <i class="bx bx-chevron-right"></i>Hermosillo
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/sin/2023/reynosa"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">
                        {" "}
                        <i class="bx bx-chevron-right"></i>Reynosa
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/sin/2023/saltillo"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">
                        {" "}
                        <i class="bx bx-chevron-right"></i>Saltillo
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/sin/2023/mazatlan"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">
                        {" "}
                        <i class="bx bx-chevron-right"></i>Mazatlán
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/sin/2023/queretaro"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">
                        {" "}
                        <i class="bx bx-chevron-right"></i>Querétaro
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/sin/2023/cuernavaca"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">
                        {" "}
                        <i class="bx bx-chevron-right"></i>Cuernavaca
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/sin/2023/puebla"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">
                        {" "}
                        <i class="bx bx-chevron-right"></i>Puebla
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/sin/2023/veracruz"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">
                        {" "}
                        <i class="bx bx-chevron-right"></i>Veracruz
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/sin/2023/coatzacoalcos"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">
                        {" "}
                        <i class="bx bx-chevron-right"></i>Coatzacoalcos
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/sin/2023/domex"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">
                        {" "}
                        <i class="bx bx-chevron-right"></i>Estado de México
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/sin/2023/villahermosa"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">
                        {" "}
                        <i class="bx bx-chevron-right"></i>Villahermosa
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/sin/2023/oaxaca"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">
                        {" "}
                        <i class="bx bx-chevron-right"></i>Oaxaca
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="w-full">
                <span className="w-full items-center pl-20">2024</span>
                <ul className="pl-5 bg-grisSinestry">
                  <li>
                    <Link
                      className="flex items-center -mt-2 h-11 py-2"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                      to="/sin/2024/tijuana"
                    >
                      <span className="">
                        <i class="bx bx-chevron-right"></i>Tijuana
                      </span>
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/sin/2024/hermosillo"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">
                        <i class="bx bx-chevron-right"></i>Hermosillo
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/sin/2024/reynosa"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">
                        <i class="bx bx-chevron-right"></i>Reynosa
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/sin/2024/saltillo"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">
                        {" "}
                        <i class="bx bx-chevron-right"></i>Saltillo
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/sin/2024/mazatlan"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">
                        {" "}
                        <i class="bx bx-chevron-right"></i>Mazatlán
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/sin/2024/queretaro"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">
                        {" "}
                        <i class="bx bx-chevron-right"></i>Querétaro
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/sin/2024/cuernavaca"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">
                        {" "}
                        <i class="bx bx-chevron-right"></i>Cuernavaca
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/sin/2024/puebla"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">
                        {" "}
                        <i class="bx bx-chevron-right"></i>Puebla
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/sin/2024/veracruz"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">
                        {" "}
                        <i class="bx bx-chevron-right"></i>Veracruz
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/sin/2024/coatzacoalcos"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">
                        {" "}
                        <i class="bx bx-chevron-right"></i>Coatzacoalcos
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/sin/2024/domex"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">
                        {" "}
                        <i class="bx bx-chevron-right"></i>Estado de México
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/sin/2024/villahermosa"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">
                        {" "}
                        <i class="bx bx-chevron-right"></i>Villahermosa
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/sin/2024/oaxaca"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">
                        {" "}
                        <i class="bx bx-chevron-right"></i>Oaxaca
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <Link
            onClick={() => {
              setSubmenuformal(false);
              setSubcontrolfact(!subcontrolfact);
              setExpanded(false);
            }}
          >
            <i
              className={`bx bx-note ${expanded ? "text-[1.5rem]" : "text-[2rem]"}`}
            ></i>

            <span className="nav-item">
              <span className="nav-item">
                {expanded ? "Control de facturas" : ""}
              </span>
            </span>
          </Link><span className="tooltip">Control de facturas</span>
          <div
            className={`absolute top-[2vh] h-[85px] z-50 bg-grisSinestry rounded-md ${subcontrolfact ? "" : "hidden"} 
           ${expanded ? "w-full ml-[10vw] mr-2" : "ml-[3vw] w-[25vw] h-auto rounded-md bg-gray-300 shadow-sm shadow-gray-100 "}`}
          >
            <ul className="pl-2">
              <li>
                <Link
                  className="flex items-center -mt-2 h-11 py-2"
                  onClick={() => {
                    setSubmenuformal(false);
                    setSubcontrolfact(false);
                    setExpanded(false);
                  }}
                  to="/ctf/controlfact2024"
                >
                  <span className="">
                    {" "}
                    <i class="bx bx-chevron-right"></i>GNP-CAPUFE Control de
                    facturas 2020-2024
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  className="flex items-center -mt-10 h-11 py-2"
                  onClick={() => {
                    setSubmenuformal(false);
                    setSubcontrolfact(false);
                    setExpanded(false);
                  }}
                  to="/ctf/controlfact20222024"
                >
                  <span className="">
                    <i class="bx bx-chevron-right"></i>GNP-CAPUFE Control de
                    facturas 2022-2024
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  className="flex items-center -mt-10 h-11 py-2"
                  onClick={() => {
                    setSubmenuformal(false);
              setSubcontrolfact(false);
              setSubmenurentabilidad(false);
              setSubmenucotizador(false);
              setSubmenucapacitacion(false);
                    setExpanded(false);
                  }}
                  to="/ctf/controlsinmill"
                >
                  <span className="">
                    <i class="bx bx-chevron-right"></i> Control Sin. Mayores a
                    un millón
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <Link
            onClick={() => {
              setSubmenuformal(false);
              setSubcontrolfact(false);
              setSubmenurentabilidad(!submenurentabilidad);
              setExpanded(false);
            }}
          >
            <i
              className={`bx bx-dollar ${expanded ? "text-[1.5rem]" : "text-[2rem]"}`}
            ></i>

            <span className="nav-item">
              <span className="nav-item">{expanded ? "Rentabilidad" : ""}</span>
            </span>
          </Link><span className="tooltip">Rentabilidad</span>
          <div
            className={`absolute -top-1 h-[65px] z-50 bg-grisSinestry rounded-md ${submenurentabilidad ? "" : "hidden"} 
           ${expanded ? "w-full ml-[10vw] mr-2" : "ml-[3vw] w-[28vw] h-auto rounded-md bg-gray-300 shadow-sm shadow-gray-100 "}`}
          >
            <ul className="pl-2">
              <li>
                <Link
                  className="flex items-center -mt-2 h-11 py-2"
                  onClick={() => {
                    setSubmenuformal(false);
                    setSubcontrolfact(false);
                    setSubmenurentabilidad(false);
                    setExpanded(false);
                  }}
                  to="/ren/aforo"
                >
                  <span className="">
                    <i class="bx bx-chevron-right"></i>Aforo vehícular y primas
                    cobradas
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  className="flex items-center -mt-10 h-11 py-2"
                  onClick={() => {
                    setSubmenuformal(false);
                    setSubcontrolfact(false);
                    setSubmenurentabilidad(false);
                    setExpanded(false);
                  }}
                  to="/ren/analisissin"
                >
                  <span className="">
                    <i class="bx bx-chevron-right"></i> Analisis de rentabilidad
                    flujo de montos 2020 al 2024
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <Link
            onClick={() => {
              setSubmenuformal(false);
              setSubcontrolfact(false);
              setSubmenurentabilidad(false);
              setSubmenucotizador(!submenucotizador);
              setSubmenucapacitacion(false);
              setExpanded(false);
            }}
          >
            <i
              className={`bx bx-calculator ${expanded ? "text-[1.5rem]" : "text-[2rem]"}`}
            ></i>

            <span className="nav-item">
              <span className="nav-item">{expanded ? "Cotizador" : ""}</span>
            </span>
          </Link><span className="tooltip">Cotizador</span>
          <div
            className={`absolute -top-1 h-[30px] z-50 bg-grisSinestry rounded-md ${submenucotizador? "" : "hidden"} 
           ${expanded ? "w-full ml-[10vw] mr-2" : "ml-[3vw] w-[28vw] h-auto rounded-md bg-gray-300 shadow-sm shadow-gray-100 "}`}
          >
            <ul className="pl-2">
              <li>
                <Link
                  className="flex items-center -mt-2 h-11 py-2"
                  onClick={() => {
                    setSubmenuformal(false);
                    setSubcontrolfact(false);
                    setSubmenurentabilidad(false);
                    setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                    setExpanded(false);
                  }}
                  to="/cot/cotizador"
                >
                  <span className="">
                    <i class="bx bx-chevron-right"></i>Cotizador
                   
                  </span>
                </Link>
              </li>
              
            </ul>
          </div>
        </li>
        <li>
          <Link
            onClick={() => {
              setSubmenuformal(false);
              setSubcontrolfact(false);
              setSubmenurentabilidad(false);
              setSubmenucotizador(false);
              setSubmenucapacitacion(!submenucapacitacion);
              setExpanded(false);
            }}
          >
            <i
              className={`bx bx-edit ${expanded ? "text-[1.5rem]" : "text-[2rem]"}`}
            ></i>

            <span className="nav-item">
              <span className="nav-item">{expanded ? "Capacitación" : ""}</span>
            </span>
          </Link><span className="tooltip">Capacitación</span>
          <div
            className={`absolute -top-1 h-[30px] z-50 bg-grisSinestry rounded-md ${submenucapacitacion? "" : "hidden"} 
           ${expanded ? "w-full ml-[10vw] mr-2" : "ml-[3vw] w-[28vw] h-auto rounded-md bg-gray-300 shadow-sm shadow-gray-100 "}`}
          >
            <ul className="pl-2">
              <li>
                <Link
                  className="flex items-center -mt-2 h-11 py-2"
                  onClick={() => {
                    setSubmenuformal(false);
                    setSubcontrolfact(false);
                    setSubmenurentabilidad(false);
                    setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                    setExpanded(false);
                  }}
                  to="/cap/capacitacion"
                >
                  <span className="">
                    <i class="bx bx-chevron-right"></i>Capacitacion
                   
                  </span>
                </Link>
              </li>
              
            </ul>
          </div>
        </li>
        <li>
          <Link
            onClick={() => {
              setSubmenuformal(false);
              setSubcontrolfact(false);
              setSubmenurentabilidad(false);
              setSubmenucotizador(false);
              setSubmenucapacitacion(false);
              setExpanded(false);
            }}
            to="https://controlcarretero.com/gnp/login.aspx?ReturnUrl=%2fgnp%2fApplication%2fdashboard.aspx"
            target="_blank"
          >
            <i
              className={`bx bx-car ${expanded ? "text-[1.5rem]" : "text-[2rem]"}`}
            ></i>

            <span className="nav-item">
              <span className="nav-item">
                {expanded ? "Control carretero" : ""}
              </span>
            </span>
          </Link><span className="tooltip">Control carretero</span>
        </li>

        <li>
          <Link
            onClick={() => {
              setSubmenuformal(false);
              setSubcontrolfact(false);
              setSubmenurentabilidad(false);
              setSubmenuestaditicas(!submenuestaditicas);
              setExpanded(false);
            }}
          >
            <i
              className={`bx bx-bar-chart-alt-2 ${expanded ? "text-[1.5rem]" : "text-[2rem]"}`}
            ></i>
            <span className="nav-item">{expanded ? "Estadísticas" : ""}</span>
          </Link><span className="tooltip">Estadísticas</span>

          <div
            className={`absolute -top-[30vh] h-[190px] z-50 bg-grisSinestry rounded-md ${submenuestaditicas ? "" : "hidden"} 
           ${expanded ? "w-full ml-[12vw] mr-2" : "ml-[3vw] w-[95vw] h-auto rounded-md bg-gray-300  "}`}
          >
            <div  className="h-[35vh] bg-grisSinestry rounded-sm" >
              <ul className="w-auto  pl-[5vh] flex flex-row justify-center text-white">
          
              <li className="w-[20vw]  bg-grisSinestry">
                <span className="w-[20vw] items-center pl-20">Control de facturas</span>
                <ul className="pl-5">
                <li>
                    <Link
                      className="flex items-center -mt-2 h-11 py-2"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                     to="/est/segcontatistas"s
                    >
                      <span className="">
                        {" "}
                        <i class="bx bx-chevron-right"></i>Seg. contratistas
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>   
               <li className="w-[20vw]  bg-grisSinestry">
                <span className=" items-center pl-20">Control caretero</span>
                <ul className="pl-5   bg-grisSinestry">
                  <li>
                    <Link
                      className="flex items-center -mt-2 h-11 py-2"
                      onClick={() => {
                        setSubmenuformal(!submenuformal);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                      to="/est/segcontatistas"
                    >
                      <span className="">
                        {" "}
                        <i class="bx bx-chevron-right"></i>Seg. contratistas
                      </span>
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/est/siniestrosespeciales"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">
                        {" "}
                        <i class="bx bx-chevron-right"></i>Control de siniestros especiales y servicios
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/est/pagoequipos"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">                       
                        <i class="bx bx-chevron-right"></i>Seg. solicitudes de pago de equipo electronicio y obra civil
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/est/reclamadoscapufe"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">
                        {" "}
                        <i class="bx bx-chevron-right"></i>Reporte e montos reclamados CAPUFE
                      </span>
                    </Link>
                  </li>                
               
                </ul>
              </li>
              <li className="w-full  bg-grisSinestry">
                <span className="w-full items-center pl-20">Siniestralidad</span>
                <ul className="pl-5   bg-grisSinestry">
                  <li >
                    <Link
                      className="flex items-center -mt-2 h-11 py-2"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                      to="/est/sesionesquincenales"
                    >
                      <span className="">
                        <i class="bx bx-chevron-right"></i>Supervición de sesiones quincenales
                      </span>
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/est/torrecontrol"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                    setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">
                        <i class="bx bx-chevron-right"></i>Torre de control
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center -mt-10 h-11 py-2"
                      to="/est/analisis"
                      onClick={() => {
                        setSubmenuformal(false);
                        setSubcontrolfact(false);
                        setSubmenurentabilidad(false);
                        setSubmenucotizador(false);
                        setSubmenucapacitacion(false);
                        setExpanded(false);
                      }}
                    >
                      <span className="">
                        <i class="bx bx-chevron-right"></i>
                        Analisis de siniestralidad
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            </div>
            
          </div>
        </li>






        
        <script
          type="module"
          src="https://cdn.jsdelivr.net/npm/@leadsales/leadclick@1.11.1/dist/leadclick/leadclick.esm.js"
        ></script>

        
        <li>
          <Link
            onClick={() => {
              setSubmenuformal(false);
              submenurentabilidad(false);
              setExpanded(false);
            }}
            to="https://api.whatsapp.com/send?phone=5215578786789&text=%C2%A1Hola%2C%20bienvenido%20al%20servicio%20de%20SINESTRY!%20%0AA%20trav%C3%A9s%20de%20este%20canal%2C%20podremos%20resolverte%20cualquier%20duda%20que%20tengas%20para%20la%20atenci%C3%B3n%20de%20siniestros.%0AEstamos%20aqu%C3%AD%20para%20ayudarte.%20%20%0A%C2%A1Gracias!"
            target="_blank"
          >
            <i
              className={`bx bxl-whatsapp ${expanded ? "text-[1.5rem]" : "text-[2rem]"}`}
            ></i>
            <span className="nav-item">
              <span className="nav-item">{expanded ? "Contacto" : ""}</span>
            </span><span className="tooltip">Contacto</span>
          </Link>
        </li>
        <li>
          <Link onClick={handleClose}>
            <i
              className={`bx bx-exit ${expanded ? "text-[1.5rem]" : "text-[2rem]"}`}
            ></i>
            <span className="nav-item">
              <span className="nav-item">{expanded ? "Salir" : ""}</span>
            </span>
          </Link><span className="tooltip">Salir</span>
        </li>
      </ul></div>
      
    </div>
  );
};
export default Sidebar3;
