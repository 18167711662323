import { React, useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Encab from "../components/Admin/Enacb";
import Sidebar3 from "../components/Sidebar3";
function LayoutAd1({ autusuario }) {
  return (
    <div className="flex w-full flex-col ">
      <Sidebar3 autusuario={autusuario} /><Encab/>
       <div className="w-[94vw] ml-[5vw] h-[100vh] ">
        
        <Outlet />
      </div>
    </div>
  );
}
export default LayoutAd1;
