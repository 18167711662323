import { React } from "react";
;

const Sinhermosillo2024 = ({ nombre, mdstatus, eseditable }) => {
    return(
        <div className=" w-full h-full">
        <iframe className=" w-full h-full border-0"
        src="https://docs.google.com/spreadsheets/d/1O6WtUOgECIgUly2ZkrvXukuYLQI2t5CMxFzYarOcvp4/edit#gid=1108941876" 
        allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe></div>
    )
}
export default Sinhermosillo2024;