import { React } from "react";
;

const Sinmazatlan2024 = ({ nombre, mdstatus, eseditable }) => {
    return(
        <div className=" w-full h-full">
        <iframe className=" w-full h-full border-0"
        src="https://docs.google.com/spreadsheets/d/12KE8-ByuSCdoyw0JFET3Rc1K0OUvJyGEaAF7HazGhns/edit#gid=553312427" 
        allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe></div>
    )
}
export default Sinmazatlan2024;