import { React } from "react";
;

const Sinhermosillo2023 = ({ nombre, mdstatus, eseditable }) => {
    return(
        <div className=" w-full h-full">
        <iframe className=" w-full h-full border-0"
        src="https://docs.google.com/spreadsheets/d/1T1eJPetj9dH16k_fCfi2fm5CNF_8bdbZMJWT-FTnhGo/edit#gid=1108941876" 
        allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe></div>
    )
}
export default Sinhermosillo2023;