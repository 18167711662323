import { Link } from "react-router-dom";
import { React, useState, useEffect } from "react";
import logosinestry from "../../auth/Login/logo_gnpsinestry.svg";
import { FaCalculator } from "react-icons/fa6";

const Splash2 = ({ autusuario }) => {
  const email = autusuario.email;
  const [mnusin, setMnusin] = useState(false);
  const [mnuctrlfac, setMnuctrlfac] = useState(false);
  const [mnuest, setMnuest] = useState(false);
  const [mnuren, setMnuren] = useState(false);
  const [mnucrt, setMnuctr] = useState(false);
  const [mnucap, setMenucap] = useState(false);
  const [mnucot, setMenucot] = useState(false);
  return (
    <div class="container  justify-center">
      <div class="absolute w-[70%] rounded-[2.5vw] shadow-gray-300 shadow -mt-[2vh] ml-[13vw] flex flex-col justify-center">
        <div className="w-full flex flex-row justify-center pt-5">
          <img class="logo-card" src={logosinestry} alt="" />
        </div>
        <div className="w-full flex flex-row justify-center">
          <span className="text-[3rem] justify-center items-center font-bold text-moradoSinestry">
            Bienvenido a Sinestry App
          </span>
        </div>
        <div className="w-full flex justify-center ">
          <div className="w-full gap-2 px-10">
            <p className="py-5 text-justify"></p>
            <p className="py-5 text-justify">
              
            </p>
          </div>
        </div>
        <div className="w-full pl-10 items-center grid grid-cols-3 gap-5 py-[2vh] text-[1.2rem]">
          <div className="col-1">
            <button
              onClick={() => {
                setMnusin(!mnusin);
                setMnuctrlfac(false);
                setMnuest(false);
                setMnuren(false);
                setMnuctr(false);
                setMenucot(false);
                setMenucap(false);
              }}
              className="card-button w-[20vw]"
            >
              <i class="bx bx-spreadsheet"></i> Siniestralidad
            </button>

            <div
              className={`relative  ${mnusin ? "" : "hidden"}  bg-gray-100 w-[42vw] ml-5 h-auto shadow-gray-300 shadow-md rounded-md`}
            >
              <ul className="flex gap-5 p-1 w-[27vw] ">
              <li className="w-[27vw]">
                  <button class="bg-moradoSinestry  text-white rounded-[20vh] w-[20vw]">
                    <i class="bx bx-spreadsheet"></i> 2023
                  </button>
                  <ul className="pl-5">
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[18vw]"
                    >
                      <Link to="sin/2023/tijuana">
                        <i class="bx bx-chevron-right"></i>
                        Tijuana
                      </Link>

                    </li>
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[18vw]"
                    >
                       <Link to="sin/2023/hermosillo">
                        <i class="bx bx-chevron-right"></i>
                        Hermosillo
                      </Link>
                    </li>
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[18vw]"
                    >
                      <Link to="sin/2023/reynosa">
                        <i class="bx bx-chevron-right"></i>
                        Reynosa{" "}
                      </Link>
                    </li>
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[18vw]"
                    >
                 <Link to="sin/2023/saltillo">
                        <i class="bx bx-chevron-right"></i>
                        Saltillo
                      </Link>
                    </li>
     
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[18vw]"
                    >
                    <Link to="sin/2023/mazatlan">
                        <i class="bx bx-chevron-right"></i>
                        Mazatlan
                      </Link>
                    </li>
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[18vw]"
                    >
                     <Link to="sin/2023/queretaro">
                        <i class="bx bx-chevron-right"></i>
                        Quéretaro{" "}
                      </Link>
                    </li>
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[18vw]"
                    >
                     <Link to="sin/2023/cuernavaca">
                        <i class="bx bx-chevron-right"></i>
                        Cuernavaca
                      </Link>
                    </li>
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[18vw]"
                    >
                      <Link to="sin/2023/puebla">
                        <i class="bx bx-chevron-right"></i>
                        Puebla{" "}
                      </Link>
                    </li>
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[18vw]"
                    >
                     <Link to="sin/2023/veracruz">
                        <i class="bx bx-chevron-right"></i>
                        Veracruz
                      </Link>
                    </li>
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[18vw]"
                    >
                     <Link to="sin/2023/coatzacoalcos">
                        <i class="bx bx-chevron-right"></i>
                        Coatzacoalcos{" "}
                      </Link>
                    </li>
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[18vw]"
                    >
                     <Link to="sin/2023/edomex">
                        <i class="bx bx-chevron-right"></i>
                        Estado de México
                      </Link>
                    </li>
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-3 w-[18vw]"
                    >
                     <Link to="sin/2023/villahermosa">
                        <i class="bx bx-chevron-right"></i>
                        Villahermosa
                      </Link>
                    </li>
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[18vw]"
                    >
                    <Link to="sin/2023/oaxaca">
                        <i class="bx bx-chevron-right"></i>
                        Oaxaca
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="w-[27vw]">
                  <button class="bg-moradoSinestry  text-white rounded-[20vh] w-[20vw]">
                    <i class="bx bx-spreadsheet"></i> 2024
                  </button>
                  <ul className="pl-5">
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[18vw]"
                    >
                      <Link to="sin/2024/tijuana">
                        <i class="bx bx-chevron-right"></i>
                        Tijuana
                      </Link>
                    </li>
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[18vw]"
                    >
                       <Link to="sin/2024/hermosillo">
                        <i class="bx bx-chevron-right"></i>
                        Hermosillo
                      </Link>
                    </li>
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[18vw]"
                    >
                      <Link to="sin/2024/reynosa">
                        <i class="bx bx-chevron-right"></i>
                        Reynosa{" "}
                      </Link>
                    </li>
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[18vw]"
                    >
                 <Link to="sin/2024/saltillo">
                        <i class="bx bx-chevron-right"></i>
                        Saltillo
                      </Link>
                    </li>
     
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[18vw]"
                    >
                    <Link to="sin/2024/mazatlan">
                        <i class="bx bx-chevron-right"></i>
                        Mazatlan
                      </Link>
                    </li>
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[18vw]"
                    >
                     <Link to="sin/2024/queretaro">
                        <i class="bx bx-chevron-right"></i>
                        Quéretaro{" "}
                      </Link>
                    </li>
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[18vw]"
                    >
                     <Link to="sin/2024/cuernavaca">
                        <i class="bx bx-chevron-right"></i>
                        Cuernavaca
                      </Link>
                    </li>
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[18vw]"
                    >
                      <Link to="sin/2024/puebla">
                        <i class="bx bx-chevron-right"></i>
                        Puebla{" "}
                      </Link>
                    </li>
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[18vw]"
                    >
                     <Link to="sin/2024/veracruz">
                        <i class="bx bx-chevron-right"></i>
                        Veracruz
                      </Link>
                    </li>
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[18vw]"
                    >
                     <Link to="sin/2024/coatzacoalcos">
                        <i class="bx bx-chevron-right"></i>
                        Coatzacoalcos{" "}
                      </Link>
                    </li>
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[18vw]"
                    >
                     <Link to="sin/2024/edomex">
                        <i class="bx bx-chevron-right"></i>
                        Estado de México
                      </Link>
                    </li>
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[18vw]"
                    >
                     <Link to="sin/2024/villahermosa">
                        <i class="bx bx-chevron-right"></i>
                        Villahermosa{" "}
                      </Link>
                    </li>
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[18vw]"
                    >
                    <Link to="sin/2024/oaxaca">
                        <i class="bx bx-chevron-right"></i>
                        Oaxaca{" "}
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-1">
            <button
              onClick={() => {
                setMnuctrlfac(!mnuctrlfac);
                setMnusin(false);
                setMnuest(false);
                setMnuren(false);
                setMnuctr(false);
                setMenucot(false);
                setMenucap(false);
              }}
              class="card-button w-[20vw]"
            >
              <i class="bx bx-note"></i> Control de facturas
            </button>
            <div
              className={`relative  ${mnuctrlfac ? "" : "hidden"} text-[1.3rem] bg-gray-100 w-[23vw] m-2 h-auto shadow-gray-300 shadow-md rounded-md`}
            >
              <ul className="p-3">
                <li
                  className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[22vw]"
                >
                  <Link to="ctf/controlfact2024">
                   
                    <i class="bx bx-chevron-right"></i> GNP-CAPUFE Control de
                    facturas 2020-2024
                  </Link>
                </li>
                <li
                  className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[22vw]"
                >
                  <Link to="ctf/controlfact20222024">
                   
                    <i class="bx bx-chevron-right"></i> GNP-CAPUFE Control de
                    facturas 2022-2024
                  </Link>
                </li>
                <li
                  className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[22vw]"
                >
                  <Link to="ctf/controlsinmill">
                 
                    <i class="bx bx-chevron-right"></i> Control Sin. Mayores a
                    un millón
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          {/* estadisticas  */}
          <div className="col-1 ">
            <button
              onClick={() => {
                setMnuctrlfac(false);
                setMnusin(false);
                setMnuest(!mnuest);
                setMnuren(false);
                setMnuctr(false);
                setMenucot(false);
                setMenucap(false);
              }}
              class="card-button w-[20vw]"
            >
              <i class="bx bx-bar-chart-alt-2"></i> Estadísticas
            </button>
            <div
              className={`relative  text-white ${mnuest ? "" : "hidden"}  bg-gray-100 w-[72vw] -ml-[100vh] h-auto text-[1.2rem] shadow-gray-300 shadow-md rounded-md`}
            >
              <ul className="flex gap-5 p-1 w-[27vw]">
                <li className="w-[27vw]">
                  <button class="bg-moradoSinestry text-blanco rounded-[20vh] w-[15vw]">
                    <i class="bx bx-spreadsheet"></i> Control carretero
                  </button>{" "}
                  <ul className="pl-5 pt-2">
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[14vw]"
                    >
                      <Link to="est/segcontatistas">
                        <i class="bx bx-chevron-right"></i>
                        Seguimineto contratistas
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="w-[35vw]">
                  <button class="bg-moradoSinestry text-blanco rounded-[20vh] w-[34vw]">
                    <i class="bx bx-spreadsheet"></i> Control de facturas
                  </button>
                  <ul className="pl-5 pt-2">
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[32vw]"
                    >
                      <Link to="est/segcontatistas">
                        <i class="bx bx-chevron-right"></i>
                        Seguimineto contratistas
                      </Link>
                    </li>
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[32vw]"
                    >
                      <Link to="est/siniestrosespeciales">
                        <i class="bx bx-chevron-right"></i>
                        Control de siniestros especiales y servicios
                      </Link>
                    </li>
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[32vw]"
                    >
                      <Link to="/est/pagoequipos">
                        <i class="bx bx-chevron-right"></i>
                        Seguimineto solicitudes de pago de equipo electronicio y obra civil
                      </Link>
                    </li>
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[32vw]"
                    >
                      <Link to="/est/reclamadoscapufe">
                        <i class="bx bx-chevron-right"></i>
                        Reporte e montos reclamados CAPUFE
                      </Link>
                    </li>
        
                  </ul>
                </li>
                <li className="w-[27vw]">
                  <button class="bg-moradoSinestry text-blanco rounded-[20vh] w-[20vw]">
                    <i class="bx bx-spreadsheet"></i> Siniestralidad
                  </button>
                  <ul className="pl-5 pt-2">
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[18vw]"
                    >
                      <Link to="est/sesionesquincenales">
                        <i class="bx bx-chevron-right"></i>
                       Supervición de sesiones quincenales
                      </Link>
                    </li>
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[18vw]"
                    >
                      <Link to="est/torrecontrol">
                        <i class="bx bx-chevron-right"></i>
                       Torre de control
                      </Link>
                    </li>
                    <li
                      className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[18vw]"
                    >
                      <Link to="est/analisis">
                        <i class="bx bx-chevron-right"></i>
                       Analisis de siniestralidad
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-1 ">
            <button
              onClick={() => {
                setMnuctrlfac(false);
                setMnusin(false);
                setMnuest(false);
                setMnuren(!mnuren);
                setMnuctr(false);
                setMenucot(!mnucot);
                setMenucap(!mnucap);
              }}
              class="card-button w-[20vw]"
            >
              <i class="bx bx-dollar"></i> Rentabilidad
            </button>
            <div
              className={`relative  ${mnuren ? "" : "hidden"} text-[1.3rem] bg-gray-100 w-[31vw] p-1 h-auto shadow-gray-300 shadow-md rounded-md`}
            >
              <ul className="p-3">
                <li 
                  className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[30vw]"
                >
                  <Link to="/ren/aforo">
                    
                    <i class="bx bx-chevron-right"></i> Aforo vehícular y primas
                    cobradas{" "}
                  </Link>
                </li>
                <li
                  className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[30vw]"
                >
                  <Link to="/ren/analisissin">
                    
                    <i class="bx bx-chevron-right"></i> Analisis de rentabilidad
                    flujo de montos 2020 al 2024{" "}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          {/* control carretero */}
          <div className="col-1 ">
            <button
              onClick={() => {
                setMnuctrlfac(false);
                setMnusin(false);
                setMnuest(false);
                setMnuren(false);
                setMnuctr(!mnucrt);
              }}
              class="card-button w-[20vw]"
            >
              <i class="bx bx-car"></i> Control carretero
            </button>
            <div
              className={`relative  ${mnucrt ? "" : "hidden"} text-[1.3rem] bg-gray-100 w-[29vw] p-1 h-auto shadow-gray-300 shadow-md rounded-md`}
            >
              <ul className="p-3">
                <li
                  className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[27vw]"
                >
                  <Link
                    to="https://controlcarretero.com/gnp/login.aspx?ReturnUrl=%2fgnp%2fApplication%2fdashboard.aspx"
                    target="_blank"
                  >
                    {" "}
                    <i class="bx bx-chevron-right"></i> Control carretero{" "}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          {/* cotizador */}
          <div className="col-1 ">
            <button
              onClick={() => {
                setMnuctrlfac(false);
                setMnusin(false);
                setMnuest(false);
                setMnuren(false);
                setMnuctr(false);
                setMenucot(!mnucot);
                setMenucap(false);
              }}
              class="card-button w-[20vw]"
            >
              <i class="bx bx-calculator"></i> Cotizador
            </button>
            <div
              className={`relative  ${mnucot ? "" : "hidden"} text-[1.3rem] bg-gray-100 w-[29vw] p-1 h-auto shadow-gray-300 shadow-md rounded-md`}
            >
              <ul className="p-3">
                <li
                  className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[27vw]"
                >
                  <Link
                    to="cot/cotizador"                  
                  >
                    
                    <i class="bx bx-chevron-right"></i> Cotizador
                  </Link>
                </li>
              </ul>
            </div>
          </div>
           {/* cotizador */}
           <div className="col-1 ">
            <button
              onClick={() => {
                setMnuctrlfac(false);
                setMnusin(false);
                setMnuest(false);
                setMnuren(false);
                setMnuctr(false);
                setMenucot(false);
                setMenucap(!mnucap);
              }}
              class="card-button w-[20vw]"
            >
              <i class="bx bx-edit"></i> Capacitación
            </button>
            <div
              className={`relative  ${mnucap ? "" : "hidden"} text-[1.3rem] bg-gray-100 w-[29vw] p-1 h-auto shadow-gray-300 shadow-md rounded-md`}
            >
              <ul className="p-3">
                <li
                  className="p-2 rounded-[60vh]
              hover:font-bold 
              hover:text-blanco
              hover:bg-moradoSinestry
               text-moradoSinestry pb-1 w-[27vw]"
                >
                  <Link
                    to="cap/capacitacion"                  
                  >
                    
                    <i class="bx bx-chevron-right"></i> Capacitación
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Splash2;
