import { React, useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Encab from "../components/Admin/Enacb";
import Sidebar3 from "../components/Sidebar3";
import Splash2 from "../paginas/Escritorio/Splash2";
function LayoutSplash({ autusuario }) {
  return (
    <>
      <div className="flex flex-row">
        <Sidebar3 autusuario={autusuario} />
        <div className="w-full ">
          <Splash2 autusuario={autusuario} />
        </div>
      </div>
    </>
  );
}

export default LayoutSplash;
